
export default {
    name: 'BankAddEmptyCard',
    props: {
        bankAccount: {
            type: Object,
            default: null,
        },
        selectedBankId: {
            type: String,
            default: null,
        },
    },
    watch: {
        bankAccount(value) {
            if (value?.player_bank_accounts?.length > 0) {
                this.setDefaultToCard(value)
            }
        },
    },
    mounted() {
        if (this.bankAccount?.player_bank_accounts?.length > 0) {
            this.setDefaultToCard(this.bankAccount)
        }
    },
    methods: {
        selectedBankAccount(id, toggle) {
            this.$emit('onSelectedBankAccount', id, toggle)
        },
        navigate() {
            this.$router.push('/payment-methods')
        },
        setDefaultToCard(bankAccount) {
            const account = bankAccount?.player_bank_accounts?.find(
                (account) => account.is_primary === true
            )

            this.selectedBankAccount(account?.id)
        },
    },
}
