
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    name: 'DepositBanner',
    mixins: [cms],
    props: {
        maxWidth: {
            type: [Number, String],
            default: '',
        },
    },
    computed: {
        ...mapGetters({
            bannerDeposit: 'cms/bannerDeposit',
        }),
        displayBannerDeposit() {
            if (this.bannerDeposit._id) {
                return this.bannerDeposit.is_visible
            }
            return false
        },
    },
}
